




























































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Navbar from "@/components/common/Navbar.vue";
import AttendanceNormService from "@/services/AttendanceNormService";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import { oldPortalUrl } from "@/envConfig";

export default Vue.extend({
  components: {
    Navbar,
    DatePicker,
  },
  props: {
    greyedOut: { type: Boolean, default: false, required: false },
  },
  data() {
    return {
      NormsName: "",

      selectedTeamIds: Array(),
      page2: false,
      AddHalfDayConstraint: false,
      firstentry: false,
      UsedName: Array(),
      teams: [],
      AttendanceNorm: {
        Name: "",
        CreationContext: "",
        CompanyId: 0,
        AttendanceNormPolicies: [
          {
            TeamId: 0,
            AttendanceTargetForTeam: [
              {
                KRAType: 0,
                FullDayTarget: "",
                HalfDayTarget: "",
              },
            ],
          },
        ],
      },

      TargetKra: {
        KRAType: 0,
        FullDayTarget: "",
        HalfDayTarget: "",
      },
      teamdata: {
        TeamId: 0,
        AttendanceTargetForTeam: [
          {
            KRAType: 0,
            FullDayTarget: "",
            HalfDayTarget: "",
          },
        ],
      },
      selectedkraIds: Array(),
      KRAs: [
        {
          id: 0,
          name: "Day Start Time",
        },
        {
          id: 1,
          name: "Retail Time",
        },
        {
          id: 2,
          name: "TC",
        },
        {
          id: 3,
          name: "Total Time",
        },
        {
          id: 4,
          name: "% Journey Plan Adherence",
        },
        {
          id: 5,
          name: "Joint Working Time",
        },
        {
          id: 6,
          name: "% Calls against planned",
        },
        {
          id: 7,
          name: "PC",
        },
        {
          id: 8,
          name: "First Call Time",
        },
        {
          id: 9,
          name: "Net Orders per day",
        },
      ],
    };
  },
  computed: {
    isNextButtonDisabled() {
      if (
        this.NormsName != "" &&
        this.selectedTeamIds.length >= 1 &&
        this.selectedkraIds.length >= 1
      )
        return false;
      else return true;
    },

    isDiabled() {
      if (this.page2 === false) return false;
      else return true;
    },

    isPublishDisabled() {
      var teamset = new Set(this.selectedTeamIds);
      var KRAset = new Set(this.selectedkraIds);
      var len = this.AttendanceNorm.AttendanceNormPolicies.length;

      if (len !== this.selectedTeamIds.length) return true;
      else {
        for (let item = 0; item < len; item++) {
          if (
            teamset.has(
              this.AttendanceNorm.AttendanceNormPolicies[item].TeamId
            ) === false
          ) {
            return true;
          } else {
            var innerlen = this.AttendanceNorm.AttendanceNormPolicies[item]
              .AttendanceTargetForTeam.length;
            if (innerlen !== this.selectedkraIds.length) return true;
            else {
              for (let k = 0; k < innerlen; k++) {
                if (
                  KRAset.has(
                    this.AttendanceNorm.AttendanceNormPolicies[item]
                      .AttendanceTargetForTeam[k].KRAType
                  ) === false
                )
                  return true;
                else if (this.AddHalfDayConstraint) {
                  if (
                    this.AttendanceNorm.AttendanceNormPolicies[item]
                      .AttendanceTargetForTeam[k].HalfDayTarget === "" ||
                    this.AttendanceNorm.AttendanceNormPolicies[item]
                      .AttendanceTargetForTeam[k].FullDayTarget === ""
                  )
                    return true;
                } else {
                  if (
                    this.AttendanceNorm.AttendanceNormPolicies[item]
                      .AttendanceTargetForTeam[k].FullDayTarget === ""
                  )
                    return true;
                }
              }
            }
          }
        }
      }
      return false;
    },
  },
  methods: {
    async GetName(): Promise<boolean> {
      return new Promise<boolean>((res) => {
        AttendanceNormService.GetName()
          .then((response) => {
            var result = response.data;
            this.UsedName = response.data;
            res(true);
          })
          .catch((error): any => {
            console.log(error);
          });
      });
    },
    async getKpi(): Promise<boolean> {
      return new Promise<boolean>((res) => {
        AttendanceNormService.GetTeams()
          .then((response) => {
            var result = response.data;
            this.teams = response.data;
            res(true);
          })
          .catch((error): any => {
            console.log(error);
          });
      });
    },
    onNextClick() {
      var nameset = new Set(this.UsedName);
      if (nameset.has(this.NormsName)) {
        alert("Policy Name already exist in the system");
      } else {
        this.page2 = true;
      }
    },
    onTeamCheckBoxClick(teamid: Number) {
      if (this.selectedTeamIds.includes(teamid)) {
        this.selectedTeamIds = this.selectedTeamIds.filter((t) => t !== teamid);
        for (
          let item = 0;
          item < this.AttendanceNorm.AttendanceNormPolicies.length;
          item++
        ) {
          if (
            this.AttendanceNorm.AttendanceNormPolicies[item].TeamId === teamid
          ) {
            this.AttendanceNorm.AttendanceNormPolicies.splice(item, 1);
            break;
          }
        }
      } else {
        this.selectedTeamIds.push(teamid);
      }
    },

    onKRACheckBoxClick(kra: Number) {
      if (this.selectedkraIds.includes(kra)) {
        this.selectedkraIds = this.selectedkraIds.filter((t) => t !== kra);
        var len = this.AttendanceNorm.AttendanceNormPolicies.length;
        for (
          let item = 0;
          item < this.AttendanceNorm.AttendanceNormPolicies.length;
          item++
        ) {
          for (
            let k = 0;
            k <
            this.AttendanceNorm.AttendanceNormPolicies[item]
              .AttendanceTargetForTeam.length;
            k++
          ) {
            if (
              this.AttendanceNorm.AttendanceNormPolicies[item]
                .AttendanceTargetForTeam[k].KRAType === kra
            ) {
              this.AttendanceNorm.AttendanceNormPolicies[
                item
              ].AttendanceTargetForTeam.splice(k, 1);
              break;
            }
          }
        }
      } else {
        this.selectedkraIds.push(kra);
      }
    },

    goBack() {
      if (this.page2 == true) {
        this.page2 = false;
      } else window.location.reload();
    },
    setFullTargetForTeam(
      event: any,
      KRAType: number,
      TeamId: number,
      type1: string
    ) {
      var check = false;
      var inner = false;
      if (!this.firstentry) {
        this.AttendanceNorm.AttendanceNormPolicies = this.AttendanceNorm.AttendanceNormPolicies.filter(
          (t) => t.TeamId !== 0
        );
        this.firstentry = true;
      }
      if (KRAType === 0 || KRAType === 8) {
        type1 = "time";
      } else if (KRAType === 1 || KRAType == 3 || KRAType === 5) {
        type1 = "timeduration";
      } else type1 = "string";
      if (this.AttendanceNorm.AttendanceNormPolicies.length) {
        var len = this.AttendanceNorm.AttendanceNormPolicies.length;
        for (let item = 0; item < len; item++) {
          if (
            this.AttendanceNorm.AttendanceNormPolicies[item].TeamId === TeamId
          ) {
            var innerlen = this.AttendanceNorm.AttendanceNormPolicies[item]
              .AttendanceTargetForTeam.length;
            check = true;
            for (let k = 0; k < innerlen; k++) {
              if (
                this.AttendanceNorm.AttendanceNormPolicies[item]
                  .AttendanceTargetForTeam[k].KRAType === KRAType
              ) {
                inner = true;
                if (type1 === "time") {
                  this.AttendanceNorm.AttendanceNormPolicies[
                    item
                  ].AttendanceTargetForTeam[k].FullDayTarget = event;
                } else if (type1 === "timeduration") {
                  this.AttendanceNorm.AttendanceNormPolicies[
                    item
                  ].AttendanceTargetForTeam[k].FullDayTarget = event;
                } else {
                  this.AttendanceNorm.AttendanceNormPolicies[
                    item
                  ].AttendanceTargetForTeam[k].FullDayTarget =
                    event.target.value;
                }
                break;
              }
            }
            if (!inner) {
              if (type1 === "time") {
                var a = {
                  KRAType: KRAType,
                  FullDayTarget: event,
                  HalfDayTarget: "",
                };
                this.AttendanceNorm.AttendanceNormPolicies[
                  item
                ].AttendanceTargetForTeam.push(a);
              } else if (type1 === "timeduration") {
                var a = {
                  KRAType: KRAType,
                  FullDayTarget: event,
                  HalfDayTarget: "",
                };
                this.AttendanceNorm.AttendanceNormPolicies[
                  item
                ].AttendanceTargetForTeam.push(a);
              } else {
                var k = {
                  KRAType: KRAType,
                  FullDayTarget: event.target.value,
                  HalfDayTarget: "",
                };
                this.AttendanceNorm.AttendanceNormPolicies[
                  item
                ].AttendanceTargetForTeam.push(k);
              }
              break;
            }
          }
        }
      }
      if (!check) {
        if (type1 === "time") {
          var entry = {
            TeamId: TeamId,
            AttendanceTargetForTeam: [
              {
                KRAType: KRAType,
                FullDayTarget: event,
                HalfDayTarget: "",
              },
            ],
          };
          this.AttendanceNorm.AttendanceNormPolicies.push(entry);
        } else if (type1 === "timeduration") {
          var entry = {
            TeamId: TeamId,
            AttendanceTargetForTeam: [
              {
                KRAType: KRAType,
                FullDayTarget: event,
                HalfDayTarget: "",
              },
            ],
          };
          this.AttendanceNorm.AttendanceNormPolicies.push(entry);
        } else {
          var entry1 = {
            TeamId: TeamId,
            AttendanceTargetForTeam: [
              {
                KRAType: KRAType,
                FullDayTarget: event.target.value,
                HalfDayTarget: "",
              },
            ],
          };
          this.AttendanceNorm.AttendanceNormPolicies.push(entry1);
        }
      }
    },
    setHalfTargetForTeam(
      event: any,
      KRAType: number,
      TeamId: number,
      type1: string
    ) {
      var check = false;
      var inner = false;
      if (!this.firstentry) {
        this.AttendanceNorm.AttendanceNormPolicies = this.AttendanceNorm.AttendanceNormPolicies.filter(
          (t) => t.TeamId !== 0
        );
        this.firstentry = true;
      }
      if (KRAType === 0 || KRAType === 8) {
        type1 = "time";
      } else if (KRAType === 1 || KRAType == 3 || KRAType === 5) {
        type1 = "timeduration";
      } else type1 = "string";
      if (this.AttendanceNorm.AttendanceNormPolicies.length) {
        var len = this.AttendanceNorm.AttendanceNormPolicies.length;
        for (let item = 0; item < len; item++) {
          if (
            this.AttendanceNorm.AttendanceNormPolicies[item].TeamId === TeamId
          ) {
            var innerlen = this.AttendanceNorm.AttendanceNormPolicies[item]
              .AttendanceTargetForTeam.length;
            check = true;
            for (let k = 0; k < innerlen; k++) {
              if (
                this.AttendanceNorm.AttendanceNormPolicies[item]
                  .AttendanceTargetForTeam[k].KRAType === KRAType
              ) {
                inner = true;
                if (type1 === "time") {
                  this.AttendanceNorm.AttendanceNormPolicies[
                    item
                  ].AttendanceTargetForTeam[k].HalfDayTarget = event;
                } else if (type1 === "timeduration") {
                  this.AttendanceNorm.AttendanceNormPolicies[
                    item
                  ].AttendanceTargetForTeam[k].HalfDayTarget = event;
                } else {
                  this.AttendanceNorm.AttendanceNormPolicies[
                    item
                  ].AttendanceTargetForTeam[k].HalfDayTarget =
                    event.target.value;
                }
              }
            }
            if (!inner) {
              if (type1 === "time") {
                var a = {
                  KRAType: KRAType,
                  FullDayTarget: "",
                  HalfDayTarget: event,
                };
                this.AttendanceNorm.AttendanceNormPolicies[
                  item
                ].AttendanceTargetForTeam.push(a);
              } else if (type1 === "timeduration") {
                var a = {
                  KRAType: KRAType,
                  FullDayTarget: "",
                  HalfDayTarget: event,
                };
                this.AttendanceNorm.AttendanceNormPolicies[
                  item
                ].AttendanceTargetForTeam.push(a);
              } else {
                var k = {
                  KRAType: KRAType,
                  FullDayTarget: "",
                  HalfDayTarget: event.target.value,
                };
                this.AttendanceNorm.AttendanceNormPolicies[
                  item
                ].AttendanceTargetForTeam.push(k);
              }
              break;
            }
          }
        }
      }
      if (!check) {
        if (type1 === "time") {
          var entry = {
            TeamId: TeamId,
            AttendanceTargetForTeam: [
              {
                KRAType: KRAType,
                FullDayTarget: "",
                HalfDayTarget: event,
              },
            ],
          };
          this.AttendanceNorm.AttendanceNormPolicies.push(entry);
        } else if (type1 === "timeduration") {
          var entry = {
            TeamId: TeamId,
            AttendanceTargetForTeam: [
              {
                KRAType: KRAType,
                FullDayTarget: "",
                HalfDayTarget: event,
              },
            ],
          };
          this.AttendanceNorm.AttendanceNormPolicies.push(entry);
        } else {
          var entry1 = {
            TeamId: TeamId,
            AttendanceTargetForTeam: [
              {
                KRAType: KRAType,
                FullDayTarget: "",
                HalfDayTarget: event.target.value,
              },
            ],
          };
          this.AttendanceNorm.AttendanceNormPolicies.push(entry1);
        }
      }
    },
    removehalfday() {
      if (!this.firstentry) {
        this.AttendanceNorm.AttendanceNormPolicies = this.AttendanceNorm.AttendanceNormPolicies.filter(
          (t) => t.TeamId !== 0
        );
        this.firstentry = true;
      }
      this.AddHalfDayConstraint = false;
      var len = this.AttendanceNorm.AttendanceNormPolicies.length;
      for (let item = 0; item < len; item++) {
        var innerlen = this.AttendanceNorm.AttendanceNormPolicies[item]
          .AttendanceTargetForTeam.length;
        for (let k = 0; k < innerlen; k++) {
          this.AttendanceNorm.AttendanceNormPolicies[
            item
          ].AttendanceTargetForTeam[k].HalfDayTarget = "";
        }
      }
    },
    getvalue(KRAType: number, TeamId: number) {
      var len = this.AttendanceNorm.AttendanceNormPolicies.length;
      for (let item = 0; item < len; item++) {
        if (
          this.AttendanceNorm.AttendanceNormPolicies[item].TeamId === TeamId
        ) {
          var innerlen = this.AttendanceNorm.AttendanceNormPolicies[item]
            .AttendanceTargetForTeam.length;
          for (let k = 0; k < innerlen; k++) {
            if (
              this.AttendanceNorm.AttendanceNormPolicies[item]
                .AttendanceTargetForTeam[k].KRAType === KRAType
            )
              return this.AttendanceNorm.AttendanceNormPolicies[item]
                .AttendanceTargetForTeam[k].FullDayTarget;
          }
        }
      }
    },
    async onSubmit() {
      this.AttendanceNorm.Name = this.NormsName;
      let value = Object.assign({}, this.AttendanceNorm);
      console.log(value);
      await AttendanceNormService.createKpi(value)
        .then((response) => {
          console.log(response);
          alert("Policy Created");
          window.location.reload();
        })
        .catch((error) => {
          if (error.response.status == 400) {
            alert("Policy not Created. Please try again");
          }
        });
    },

    gethalfvalue(KRAType: number, TeamId: number) {
      var len = this.AttendanceNorm.AttendanceNormPolicies.length;
      for (let item = 0; item < len; item++) {
        if (
          this.AttendanceNorm.AttendanceNormPolicies[item].TeamId === TeamId
        ) {
          var innerlen = this.AttendanceNorm.AttendanceNormPolicies[item]
            .AttendanceTargetForTeam.length;
          for (let k = 0; k < innerlen; k++) {
            if (
              this.AttendanceNorm.AttendanceNormPolicies[item]
                .AttendanceTargetForTeam[k].KRAType === KRAType
            )
              return this.AttendanceNorm.AttendanceNormPolicies[item]
                .AttendanceTargetForTeam[k].HalfDayTarget;
          }
        }
      }
    },
  },
  mounted() {
    this.getKpi();
    this.GetName();
  },
});
