import {IPostNorms } from '@/interfaces/attendancenorm';
import client from '../apiService';

 class AttendanceNormService {
    async GetTeams(){
        const url = `AttendanceNorm/GetActiveTeam`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
       return result;
    }
    async createKpi(Norm: IPostNorms){
        const url = `AttendanceNorm/CreateAttendanceNorm`;
        return await client.post(url, Norm, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    async GetName(){
        const url = `AttendanceNorm/GetUsedName`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
      return result;

    }
    async GetActiveNorm(searchTerm: string, page: number){
        const url = `AttendanceNorm/GetNorms?searchTerm=${searchTerm}&page=${page}`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
      return result;

    }
    async GetInActiveNorm(searchTerm: string, page: number){
        const url = `AttendanceNorm/GetInactiveNorms?searchTerm=${searchTerm}&page=${page}`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
      return result;

    }
    deactivateNorm(normId: number){
        const url = `AttendanceNorm/DeactivateNorm?normId=${normId}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
}

export default new AttendanceNormService();

